// Import mixins
@import 'src/mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-vb-theme='dark'] {
  .ant-slider {
    &-rail {
      background: $dark-gray-4;
    }
    &-track {
      background: $primary;
    }
    &-dot {
      border-color: $dark-gray-3;
      &-active {
        border-color: $primary;
      }
    }

    &:hover {
      .ant-slider-rail {
        background: $dark-gray-3;
      }
    }
  }
}
