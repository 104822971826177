// this is shared mixins file for all components from the app,
// there you can easily add your own variables / imports or redefine
// existing without touching default packages for future updates

@import 'app/@vb/css/mixins.scss'; // import KIT mixins

@media (max-height: 1600px) and (max-width: 2000px) {
  /* This part is only read by viewports wider than 1600 pixels */
  .table-ticketing {
    thead {
      font-size: 14px;
    }
    tbody {
      font-size: 14px;
    }
  }
  .container-table-administration {
    max-height: calc(75vh - 30px);
    overflow: auto;
  }
  .container-table-dfsp-without-dn {
    height: 65vh;
    overflow: auto;
  }
  .container-table-dfsp {
    min-height: 20vh;
    max-height: 51vh;
    // height: 51vh;
    overflow: auto;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  /* This part is only read by viewports wider than 1200 pixels */
  .table-ticketing {
    thead {
      font-size: 12px;
    }
    tbody {
      font-size: 12px;
    }
  }
  .container-table-administration {
    max-height: calc(65vh - 30px);
    overflow: auto;
  }
  .container-table-dfsp-without-dn {
    height: 49vh;
    overflow: auto;
  }
  .container-table-dfsp {
    min-height: 10vh;
    max-height: 30vh;
    overflow: auto;
  }
}
// $text: #000;
// $success: green;

.dialog-top {
  position: sticky;
  bottom: 0; /* no unit */
  z-index: 10; /* no unit */
}

.dialog-bottom {
  background-color: white;
  // position: absolute;
  // bottom: 30px;
  // right: 20px;
  // left: 20px;
  position: sticky;
  bottom: 0;
  // right: 20px;
  // left: 20px;
  z-index: 10;
}

.dialog-bottom-inv {
  background-color: white;
  position: absolute;
  bottom: 30px;
  right: 20px;
  left: 20px;
  z-index: 10;
}

.btn-small {
  padding-top: 12px;
  button {
    font-size: 1px;
    margin-right: 1vw;
    line-height: 13px !important;
    mat-icon {
      font-size: 20px;
    }
  }
}

.cursor-pointer:hover {
  background-color: rgb(194, 193, 193);
  .buttons-white {
    mat-icon {
      color: white;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

//   .disabled:hover {
// 	pointer-events:none;
// 	opacity:0.5;
//   }
.buttons-white {
  padding: 13px;
  mat-icon {
    color: rgb(167, 167, 167);
  }
  // background-color: rgb(194, 193, 193);
}

th.mat-header-cell {
  background-color: whitesmoke;
}

.spinner-container {
  display: flex;
  justify-content: center;
  padding: 15%;
  align-items: center;
}

.hidden-file-input {
  display: none;
}

.mat-paginator-sticky {
  bottom: 0; /* no unit */
  position: sticky; /* no unit */
  z-index: 10;
}

// .stretch-badge > .mat-badge-content {
// 	width: inherit !important;
// 	border-radius: 13px;
// 	left: unset !important;
//   }
.stretch-badge > .mat-badge-content {
  width: auto;
  display: grid;
  min-width: 24px;
  min-height: 20px;
  text-align: center;
  align-items: center;
  padding: 2px 1px;
  top: -10px;
  right: -22px;
}
.mat-badge-content {
  width: auto;
  display: grid;
  min-width: 24px;
  min-height: 20px;
  text-align: center;
  align-items: center;
  padding: 1px 0; /* no unit */
  top: -10px; /* no unit */
  right: -22px;
}

.xlsx-upload > input {
  display: none;
}

table {
  width: 100%;
  height: fit-content;
}
// .hide-button{
// 	visibility:hidden
// }

.hide-edit-dialog-transfer-document {
  display: none !important;
}

.custom-dialog-container .mat-dialog-container {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
  position: relative;
}
